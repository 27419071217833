<template>
  <div class="rubric">
    <div class="rubric__info">
      <h4 class="rubric__title">
        {{ rubric.title }}
      </h4>
      <div class="rubric__description">
        {{ rubric.description }}
      </div>
      <div class="rubric__points">
        0 - {{ rubric.points_max }} балл
      </div>
    </div>
    <v-spacer/>
    <div v-if="showActions" class="rubric_actions">
      <v-btn @click="() => $emit('edit')" icon>
        <v-icon>
          mdi-pencil
        </v-icon>
      </v-btn>
      <v-btn @click="() => $emit('delete')" icon>
        <v-icon>
          mdi-delete
        </v-icon>
      </v-btn>
    </div>

  </div>
</template>

<script>
export default {
  name: "EventRubric",
  props: ['rubric', 'showActions']
}
</script>

<style scoped>
.rubric{
  padding: 20px;
  background: #f5f5f5;
  border-radius: 20px;
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.rubric__title{
  font-size: 16px;
}

.rubric__description{
  font-weight: 300;
}
</style>
