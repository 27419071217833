<template>
  <v-sheet class="pa-2">
    <div class="pa-2">
      <v-dialog max-width="500" v-model="editRubricDialog">
        <template v-slot:activator="{ on }">
          <v-btn outlined rounded color="green"
                 dark
                 v-on="on"
          >
            <v-icon>mdi-plus</v-icon>
            Добавить критерий
          </v-btn>
        </template>
        <RubricCreateEditComponent v-if="editRubricDialog" :edit_rubric="edit_rubric"
                                   :event_id="$store.state.events.currentEvent.id"
                                   @input="edit_rubric = null"
                                   @close="() => {editRubricDialog = false; edit_rubric = null;}"/>
      </v-dialog>
    </div>

    <Empty class="mx-auto my-3" title="Критериев оценки нет," description="но вы можете создать "
           v-if="!$store.state.events.currentEvent.rubrics.length"/>
    <EventRubric :show-actions="true" @edit="() => {edit_rubric = rubric; editRubricDialog = true;}" :rubric="rubric"
                 @delete="() => removeRubric(rubric.id)" :key="rubric.id"
                 v-for="rubric in $store.state.events.currentEvent.rubrics"/>


  </v-sheet>
</template>

<script>
import RubricCreateEditComponent from "@/components/OldEvents/RubricCreateEditComponent";
import EventRubric from "@/components/Events/EventParts/Widgets/EventRubric";
import Empty from "@/components/Common/Empty";

export default {
  name: "EventRubricsComponent",
  components: {
    Empty,
    EventRubric,
    RubricCreateEditComponent
  },
  props: {},
  data() {
    return {
      editRubricDialog: false,
      edit_rubric: null,
      loading: true
    }
  },
  methods: {
    removeRubric(id) {
      if (confirm("Вы действительно хотите удалить критерий? Если по нему выставлены оценки, то они удаляться!!!")) {
        this.loading = true;
        this.$store.dispatch('deleteRubric', {id}).then(() => {
          this.loading = false;
        })
      }
    }
  },
  mounted() {
  }
}
</script>

<style scoped>

</style>
