<template>
  <v-card :loading="loading">
    <v-card-title>
      <span class="headline">Создание критерия</span>
    </v-card-title>
    <v-alert type="error" :key="i" v-for="(error, i) in errors">
      {{ error[0] }}
    </v-alert>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-form>
              <v-text-field :error-messages="errors.title" v-model="rubric.title"
                            :rules="[v => !!v || 'Нужно заполнить']"  placeholder="Участие"
                            label="Название критерия" required></v-text-field>
              <v-text-field :error-messages="errors.description" v-model="rubric.description"
                            :rules="[v => !!v || 'Нужно заполнить']" placeholder="Начисляется в случае участия"
                            clearable label="Описание критерия" required></v-text-field>
              <v-text-field :error-messages="errors.points_max" v-model="rubric.points_max"
                            :rules="[v => !!v  || 'Нужно заполнить', v => v > 0 || 'Не меньше 0 !']" placeholder="5"
                            type="number" clearable label="Максимальный балл" required></v-text-field>
            </v-form>
          </v-col>

        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn outlined rounded @click="save" color="green">Добавить критерий</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "RubricCreateEditComponent",
  props: {
    event_id: {},
    edit_rubric: null
  },
  data() {
    return {
      rubric: {
        title: '',
        description: '',
        points_max: '',
      },
      errors: {},
      loading: false
    }
  },
  mounted() {
    this.rubric = {...this.edit_rubric};
  },
  methods: {
    save() {
      this.loading = true;
      this.$store.dispatch('storeRubric', {
        id: this.rubric.id,
        title: this.rubric.title,
        description: this.rubric.description,
        points_max: this.rubric.points_max,
        event_id: this.event_id
      }).then(() => {
        this.loading = false;
        this.rubric = {
          title: '',
          description: '',
          points_max: '',
        };
        this.$emit('close');
      }).catch((e) => {
        this.loading = false;
        this.errors = e.response.data.errors;
      })
    }
  }
}
</script>

<style scoped>

</style>
